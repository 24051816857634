import Image from "next/image";
import { useEffect, useState } from "react";

import { NarrativeSummary } from "@/model/api";
import { getArticleImageUrl as getUrl } from "@/util/article";

const fallbackSrc = "/img/thumbnails/narrative-thumbnail-fallback.jpg";

export interface ArticleImageProps {
  ns: NarrativeSummary;
  priority?: boolean;
  sizes?: string;
}

/**
 * Loads the article image for a given narrative summary
 */
export const ArticleImage = ({ ns, priority, sizes }: ArticleImageProps) => {
  const [imgSrc, setImgSrc] = useState(getUrl(ns));
  useEffect(() => setImgSrc(getUrl(ns)), [ns]);
  return (
    <>
      <Image
        src={imgSrc}
        onError={() => setImgSrc(fallbackSrc)}
        alt={`article image for ${ns.Ticker}`}
        className="rounded-lg"
        priority={priority}
        data-testid="test-article-img"
        fill
        sizes={sizes}
        style={{
          objectFit: "cover",
        }}
      />
      <div className="animate-pulse absolute inset-0 bg-gray-200 rounded-lg -z-10" />
    </>
  );
};
