interface Props {
  className?: string;
  title: string;
}

export const BarTitle = ({ title, className }: Props) => (
  <p
    className={`relative tracking-wide leading-none uppercase pl-4 font-bold ${className}`}
  >
    <span className="bg-fi-red-secondary w-1.5 h-full lg:h-[70%] absolute inset-y-0 left-0 top-0 lg:top-1" />
    {title}
  </p>
);
