import { NavOptions } from "@/components/ui/lite/NavOptions";

import { Nav } from "./Nav";

export const Header = () => (
  <div className="relative w-full bg-fi-black text-white">
    <div className="container w-11/12 my-0 mx-auto h-full">
      <div className="relative w-full min-h-18 lg:min-h-28">
        <NavOptions />
        <Nav />
      </div>
    </div>
  </div>
);
