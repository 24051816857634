import dynamic from "next/dynamic";
import React from "react";

const SearchInput = dynamic(() =>
  import("../searchInput/SearchInputExpandable").then(
    (mod) => mod.SearchInputExpandable
  )
);

const LiteMemberContainer = dynamic(
  () => import("./LiteMemberContainer").then((mod) => mod.LiteMemberContainer),
  {
    loading: () => (
      <div className="whitespace-nowrap w-[76px] text-gray-500 px-2 lg:px-4 py-2 lg:py-0">
        Sign in
      </div>
    ),
    ssr: false,
  }
);

export const NavOptions = () => (
  <div className="p-0 text-sm font-semibold flex items-center justify-between relative inset-y-auto lg:absolute lg:inset-y-0 lg:right-0 h-16 md:h-auto">
    <div className="relative top-0 mr-2 w-full">
      <SearchInput />
    </div>
    <LiteMemberContainer />
  </div>
);
