import React from "react";
import { useMediaQuery } from "react-responsive";

/**
 * Media query for mobile viewports. Defaults to 1024px, which is the same as the `lg` modifier width in tailwind.
 * @returns A boolean indicating if the viewport is mobile.
 */
const useIsMobile = () => useMediaQuery({ maxWidth: 1024 });

export default useIsMobile;
