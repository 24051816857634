export const AppTitleHeader = () => (
  <>
    <div
      className="w-full h-full flex justify-center items-center font-black text-2xl pb-0 lg:text-5xl lg:py-2"
      data-testid="title-header"
    >
      <span className="h-fit">Foliko Insights</span>
    </div>
    <div className="hidden lg:block text-sm">Investor News for a New Age</div>
  </>
);
